import SupportApiService, { ErrorLevelEnum } from '@api/support-api-service';


class KibanaService {
    private readonly _supportApiService: SupportApiService;

    constructor(supportApiService: SupportApiService) {
        this._supportApiService = supportApiService;
    }

    public async sendLog(error: Error, orderId: number, dataToSend?: any[], level?: ErrorLevelEnum): Promise<void> {
        const { stack, message } = error;

        const errorMessage = orderId === -1 ? `Problem with created order. Error: ${message}` :
            orderId === -2 ? `OrderID: unknown. Error: ${message}` : `OrderID: ${orderId}. Error: ${message}`;

        // eslint-disable-next-line no-console
        console.warn(errorMessage);

        await this._supportApiService.sendLogToKibana(
            'NEW UI',
            errorMessage,
            level || ErrorLevelEnum.CRITICAL,
            stack,
            dataToSend,
        );
    }

    public async internetConectionLog(error: string, date: string | null): Promise<void> {
        await this._supportApiService.sendLogToKibana(
            'NEW UI',
            error,
            ErrorLevelEnum.LOG,
            undefined,
            [date],
        );
        localStorage.removeItem('no internet conection');
    }
}


export default KibanaService;
