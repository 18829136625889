import get from 'lodash/get';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';
import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';

import {
    AttributeTypes, PrepareTypes, TConvertArrayToCollection, TypesForCollection,
} from '@services/order/models';
import convertArrayToCollection from '@core/helpers/convertArrayToCollection';
import {
    ICountryDataBase,
    IOrder,
    IOrderComment, IOrderForService, IRecallLimits,
    ISimilarOrder,
    ISmsFromErp,
    ISubStatus,
    ISubStatusInfo,
} from '@api/order-api-service/models';
import { ISmsFromErpModelSnapshotIn } from '@models/mobx-state-tree/customer-mode/SmsFromErp.model';
import { IUserCountry } from '@api/user-api-service/models';
import { ICountryModelSnapshotIn } from '@models/mobx-state-tree/country.model';
import { ISourceType } from '@api/source-api-service/models';
import { ISourceTypeModelSnapshotIn } from '@models/mobx-state-tree/sourceType';
import { ICommunicationQualityItem } from '@api/support-api-service/model';
import { ICommunicationsQualityModelSnapshotIn } from '@models/mobx-state-tree/communicationsQuality.model';
import { ICountryCurrencyItemWithCodeKey } from '@api/country-api-service/models';
import { ICurrencyModelSnapshotIn } from '@models/mobx-state-tree/currency.model';
import {
    AttrsKey,
    IAdditionalPrice,
    IFastChangeQueueRawData,
    IFormAttributes,
    IVirtualButton,
} from '@interfaces/form.interface';
import { IFastChangeQueueModelSnapshotIn, IFormModelSnapshotIn } from '@models/mobx-state-tree/form.model';
import { ISubStatusModelSnapshotIn } from '@models/mobx-state-tree/subStatus.model';
import { IFormAttributesModelSnapshotIn } from '@models/mobx-state-tree/formAttributes.model';
import { ICustomerPhoneSnapshotIn } from '@models/mobx-state-tree/customerPhone.model';
import getNumberFromString from '@/utils/getNumberFromString';
import { ICallAutomatizationModelSnapshotIn } from '@models/mobx-state-tree/callAutomatization.model';
import { DEFAULT_AUTOCALL_STATE, DEFAULT_ORDER_FORM } from '@core/constants/defaultStoreItems';
import { IChangeStatusButtonSnapshotIn } from '@models/mobx-state-tree/changeStatusButtons.model';
import { IChangeStatusVirtualButtonSnapshotIn } from '@models/mobx-state-tree/changeStatusVirtualButton.model';
import { IOrderStatusModelSnapshotIn } from '@models/mobx-state-tree/orderStatus.model';
import { IConditionForFreeShippingModelSnapshotIn } from '@models/mobx-state-tree/conditionForFreeShipping.model';
import { ISimilarOrderModelSnapshotIn } from '@models/mobx-state-tree/similarOrder.model';
import { IOrderCommentModelSnapshotIn } from '@models/mobx-state-tree/orderComment.model';
import { ICallHistory } from '@api/call-api-service/model';
import { ICallHistoryModelSnapshotIn } from '@models/mobx-state-tree/callHistory.model';
import { ICallHistoryStatusModelSnapshotIn } from '@models/mobx-state-tree/callHistoryStatus.model';
import { ICallHistoryUserModelSnapshotIn } from '@models/mobx-state-tree/callHistoryUser.model';
import { ICallHistoryProviderModelSnapshotIn } from '@models/mobx-state-tree/callHistoryProvider.model';
import { ICurrentOrderSnapshotIn } from '@models/mobx-state-tree/currentOrder.model';
import { IOrderSourceModelSnapshotIn } from '@models/mobx-state-tree/orderSource.model';
import { IOrderTypeModelSnapshotIn } from '@models/mobx-state-tree/orderType.model';
import { IQueueTypeModelSnapshotIn } from '@models/mobx-state-tree/queueType.model';
import { ICustomerComponents } from '@interfaces/customerComponents.interface';
import { ICallsCountModelSnapshotIn } from '@models/mobx-state-tree/callsCount.model';
import { IBlockButtonNoAnswerModelSnapshotIn } from '@models/mobx-state-tree/blockButtonNoAnswer.model';

type TOrderForService = IOrderForService;


// TODO Провести глобальный рефакторинг
class OrderPrepareMetadata {
    private _customerData: Record<AttrsKey, string> | null = null;

    public maskPhones: boolean | null = null;

    public currentCountry: ICountryDataBase | null = null;

    public customerMainPhone: string | null = null;

    public set customerData(data: ICustomerComponents | string | null) {
        this._customerData = this.prepareCustomerData(data);
    }

    public prepareForCollection(
        array: TypesForCollection[],
        type: PrepareTypes,
    ): Record<string, TConvertArrayToCollection> {
        if (!array || !array.length) return {};

        let prepareMethod: any;

        switch (type) {
            case PrepareTypes.MAIN_DATA: {
                prepareMethod = this.prepareOfMainData;
                break;
            }

            case PrepareTypes.SIMILAR_ORDERS: {
                prepareMethod = this.prepareSimilarOrder;
                break;
            }

            case PrepareTypes.SOURCE_TYPES: {
                prepareMethod = this.prepareSourceTypes;
                break;
            }

            case PrepareTypes.COUNTRIES: {
                prepareMethod = this.prepareCountry;
                break;
            }

            case PrepareTypes.CALL_HISTORIES: {
                prepareMethod = this.prepareOrderCallHistoryItem;
                break;
            }

            case PrepareTypes.ORDER_COMMENTS: {
                prepareMethod = this.prepareOrderCommentItem;
                break;
            }

            case PrepareTypes.COMMUNICATIONS_QUALITY: {
                prepareMethod = this.prepareCommunicationsQuality;
                break;
            }

            case PrepareTypes.CURRENCIES: {
                prepareMethod = this.prepareCurrencyItems;
                break;
            }

            default: {
                throw new Error('Unrecognized type for transforms.');
            }
        }

        const dataForCollection = map<TypesForCollection, TConvertArrayToCollection>(array, prepareMethod);

        return convertArrayToCollection<TConvertArrayToCollection>(dataForCollection);
    }

    public _convertTextMessagesForModel(array: ISmsFromErp[] | null, foreignId?: number): ISmsFromErpModelSnapshotIn[] | [] {
        if (!array) {
            return [];
        }

        return map<ISmsFromErp, ISmsFromErpModelSnapshotIn>(
            array,
            (sms) => ({
                id: foreignId || sms.id,
                template: sms.template,
                text: sms.sms_text,
                status: sms.status,
                error: sms.error || '',
                phone: sms.phone,
                createdAt: sms.created_at && String(sms.created_at) || '',
            }),
        );
    }

    public prepareCountry(country: IUserCountry): ICountryModelSnapshotIn {
        return {
            id: String(country.id),
            name: country.name,
            charCode: country.char_code,
            phoneEditingRange: country.phone_editing_range,
            minTimeWaitCall: country.min_time_wait_call,
            pddLimitEndCall: country.pdd_limit_end_call,
            pddLimit: country.webrtc_pdd_limit,
        };
    }

    public prepareOfMainData({ id, name }: { id: string | number; name: string }): { id: string; name: string } {
        return {
            id: String(id),
            name: String(name),
        };
    }

    public prepareSourceTypes(sourceTypes: ISourceType): ISourceTypeModelSnapshotIn {
        return {
            id: String(sourceTypes.id),
            name: String(sourceTypes.name),
            description: String(sourceTypes.description),
        };
    }

    public prepareCommunicationsQuality(problem: ICommunicationQualityItem): ICommunicationsQualityModelSnapshotIn {
        return {
            id: String(problem.id),
            question: problem.question,
        };
    }

    public prepareCurrencyItems(currencyItems: ICountryCurrencyItemWithCodeKey): ICurrencyModelSnapshotIn {
        return {
            id: currencyItems.id,
            code: currencyItems.code,
        };
    }

    public prepareFastChangeQueues(data: IFastChangeQueueRawData | undefined): IFastChangeQueueModelSnapshotIn[] {
        if (!data || !data.queue || !data.queue.length) {
            return [];
        }

        return map(data.queue, (queue) => ({
            id: queue.id,
            name: queue.name,
        }));
    }

    public convertSubStatusInfo(array: ISubStatusInfo[]): Record<string, ISubStatusModelSnapshotIn> {
        if (!array || !array.length) return {};

        const subStatuses: ISubStatusModelSnapshotIn[] = reduce(array, (
            a: ISubStatusModelSnapshotIn[],
            item: ISubStatusInfo,
        ): ISubStatusModelSnapshotIn[] => {
            forEach(item.sub_statuses, (status: ISubStatus) => a.push({
                id: String(status.code),
                status: item.status,
                name: status.name,
            }));

            return a;
        }, []);

        return convertArrayToCollection<ISubStatusModelSnapshotIn>(subStatuses);
    }

    public initializeFormAttributeValue(key: AttrsKey): string {
        let value = '';

        if (key && key.length) {
            if (!this._customerData) {
                return value;
            }

            value = this._customerData[key] || value;

            if (value && value.length) {
                return value;
            }
        }

        return String(value);
    }

    public initializeDefaultZipCodeAttribute = (): IFormAttributesModelSnapshotIn => ({
        id: AttrsKey.CUSTOMER_ZIP,
        formAttributeId: 653,
        name: AttrsKey.CUSTOMER_ZIP,
        label: 'Zip Code',
        required: true,
        length: 100,
        isKlader: false,
        isKladrOnly: false,
        isCoordinate: false,
        isDisabledUserData: false,
        type: 'string',
        _value: this.initializeFormAttributeValue(AttrsKey.CUSTOMER_ZIP),
    });

    public convertFormAttributes(
        dynamicForms: IFormAttributes[],
        typeForms: AttributeTypes,
        isACreatedOrder?: boolean,
    ): Record<string, IFormAttributesModelSnapshotIn> {
        if (!dynamicForms || !dynamicForms.length) {
            return {};
        }

        const formAttributes = reduce(dynamicForms, (
            a: IFormAttributesModelSnapshotIn[],
            attr: IFormAttributes,
        ): IFormAttributesModelSnapshotIn[] => {
            const { name } = attr;
            const _value = this.initializeFormAttributeValue(name) || '';
            let customerPhone: ICustomerPhoneSnapshotIn | undefined;

            if (name === AttrsKey.CUSTOMER_PHONE) {
                customerPhone = {
                    id: 'CustomerPhoneModel',
                    maskPhone: this.maskPhones && _value ? this.maskPhones : undefined,
                    phoneEditingRangeInfo: this.currentCountry?.phone_editing_range || undefined,
                    originalValue: getNumberFromString(_value),
                    isACreatedOrder,
                };
            }

            const values = attr?.values ? JSON.parse(attr.values) : undefined;

            const _key = name === 'customer_gender' ? _value : '';
            const _option = name === 'customer_gender'  && values ? { label: values[_value] || '', value: values[_value] || '' } :
                { label: _value, value: _value };

            const formAttribute: IFormAttributesModelSnapshotIn = {
                id: name,
                formAttributeId: attr.id,
                label: attr.label,
                required: attr.required,
                type: attr.type,
                regex: attr.regex,
                length: attr.length,
                isKlader: attr.is_klader,
                isKladrOnly: attr.is_kladr_only,
                isCoordinate: attr.is_coordinate,
                isDisabledUserData: attr.is_disabled_user_data,
                name,
                customerPhone,
                _value,
                data: [],
                values: values ? Object.values(values) : [],
                keys: values ? Object.keys(values) : [],
                _option,
                _key,
                minLength: attr.min_length,
            };

            a.push(formAttribute);

            return a;
        }, []);

        if (typeForms === AttributeTypes.ORDER_ADDRESSES) {
            const isAZipCode = formAttributes
                .some((attr: IFormAttributesModelSnapshotIn) => attr.name === AttrsKey.CUSTOMER_ZIP);

            if (!isAZipCode) {
                formAttributes.push(this.initializeDefaultZipCodeAttribute());
            }
        }

        return convertArrayToCollection<IFormAttributesModelSnapshotIn>(formAttributes);
    }

    public prepareCallAutomatization(): ICallAutomatizationModelSnapshotIn {
        return {
            ...DEFAULT_AUTOCALL_STATE,
            automaticCallsAmount: this.currentCountry?.wsca_count || 1,
        };
    }

    public prepareCallsCount(limitCalls: number | null): ICallsCountModelSnapshotIn {
        return {
            limitCalls,
        };
    }

    public prepareBlockButtonNoAnswer(acceptTimeLimitForBlockNoAnswer: number | null): IBlockButtonNoAnswerModelSnapshotIn {
        return {
            acceptTimeLimitForBlockNoAnswer,
            blockedNoAnswer: false,
            timeLivePassed: 0,
        };
    }

    private _prepareRecallLimitSeconds(recallLimits: IRecallLimits[] | null, orderType?: number | null): number | null {
        if (recallLimits && orderType) {
            return recallLimits.reduce((acc: number | null, el) =>
                el.order_type_id === orderType ? acc = el.limit_seconds : acc, null);
        }
        return null;
    }

    public prepareCurrentCountry(orderType?: number | null): ICountryModelSnapshotIn {
        if (!this.currentCountry) {
            return {
                id: 'none',
                name: '',
            };
        }

        const {
            id,
            name,
            char_code: charCode,
            phone_editing_range: phoneEditingRange,
            min_time_wait_call: minTimeWaitCall,
            pdd_limit_end_call: pddLimitEndCall,
            webrtc_pdd_limit: pddLimit,
            status_automatization_active: statusAutomatizationActive = false,
            no_answer_if_busy: noAnswerIfBusy,
            recall_to_min: recallToMin,
            recall_limits: recallLimits,
            noanswer_after_sec: noAnswerAfterSec,
            stock_alerting_enabled: stockAlertingEnabled,
            start_call_automatic: startCallAutomatic,
            wsca_count: wscaCount,
            wsca_interval: wscaInterval,
        } = this.currentCountry;

        return {
            id: String(id),
            name,
            charCode,
            phoneEditingRange,
            minTimeWaitCall: minTimeWaitCall || 0,
            pddLimitEndCall,
            pddLimit,
            statusAutomatizationActive,
            noAnswerIfBusy,
            recallToMin,
            recallLimitSeconds: this._prepareRecallLimitSeconds(recallLimits, orderType),
            noAnswerAfterSec: noAnswerAfterSec || 0,
            stockAlertingEnabled: stockAlertingEnabled || false,
            startCallAutomatic,
            automaticCallsAmount: wscaCount,
            automaticCallsInterval: wscaInterval,
        };
    }

    public prepareChangeStatusButtons(
        buttons: Record<string, string>,
        _virtualButtons: Record<string, IVirtualButton>,
    ): IChangeStatusButtonSnapshotIn[] {
        const virtualButtonsRoot = map(_virtualButtons, (value, index) => ({
            id: parseInt(index, 10),
            name: value.name,
            substatuses: value.substatuses,
        }));

        const mainButtonsIds = map(buttons, (item: string, index: string) => parseInt(index, 10));

        const virtualButtonsRootNotInMainButtons = virtualButtonsRoot.filter((item) => !mainButtonsIds.includes(item.id));

        const partOne = map(buttons, (item: string, index: string): IChangeStatusButtonSnapshotIn => {
            let virtualStatuses: IChangeStatusVirtualButtonSnapshotIn[] = [];

            const root = virtualButtonsRoot.filter((item) => item.id === parseInt(index, 10));

            if (root.length) {
                const rootItem = root[0];
                virtualStatuses = map(rootItem.substatuses, (name, index) => ({
                    id: parseInt(index, 10),
                    name,
                }));
            }

            return ({
                id: parseInt(index, 10),
                name: item,
                includeSelf: true,
                virtualStatuses,
            });
        });

        const partTwo = map(virtualButtonsRootNotInMainButtons, (item): IChangeStatusButtonSnapshotIn => {
            const virtualStatuses: IChangeStatusVirtualButtonSnapshotIn[] = map(item.substatuses, (name, index) => ({
                id: parseInt(index, 10),
                name,
            }));

            return ({
                id: item.id,
                name: item.name,
                includeSelf: false,
                virtualStatuses,
            });
        });


        return [...partOne, ...partTwo];
    }

    public prepareStatuses(
        _buttons: Record<string, string>,
        _virtualButtons: Record<string, IVirtualButton>,
    ): Record<number, IOrderStatusModelSnapshotIn> {
        const virtualButtonsSubStatuses: IOrderStatusModelSnapshotIn[] = [];

        const virtualButtonsRoot = map(_virtualButtons, (value, index) => {
            const sub = map(value.substatuses, (item, index) => ({
                id: parseInt(index, 10),
                name: item,
            }));

            virtualButtonsSubStatuses.push(...sub);

            return {
                id: parseInt(index, 10),
                name: value.name,
            };
        });

        const buttons = map(_buttons, (value, index) => ({
            id: parseInt(index, 10),
            name: value,
        }));

        const statuses = uniqBy([...virtualButtonsRoot, ...buttons, ...virtualButtonsSubStatuses], 'id');

        return convertArrayToCollection<IOrderStatusModelSnapshotIn>(statuses);
    }

    public prepareAdditionalPrice(additionalPrice: IAdditionalPrice | null): IConditionForFreeShippingModelSnapshotIn | null {
        const id = 'condition_for_free_delivery';

        if (!additionalPrice) {
            return null;
        }

        return {
            id,
            additionalType: additionalPrice.addition_type.type,
            condition: additionalPrice.condition,
            quantity: additionalPrice.sign,
            useGift: additionalPrice.use_gift,
            usePromo: additionalPrice.use_promo,
        };
    }

    public getArrayAdditionalPhones = (phones: string | null): string[] => {
        return phones ? phones.split(', ') : [];
    };

    public prepareForm(
        order: TOrderForService | null,
    ): IFormModelSnapshotIn {
        const date = new Date();

        const form = order!.form;

        if (form === null) {
            return DEFAULT_ORDER_FORM;
        }

        return {
            id: `${form.id}-${date.getTime()}`,
            useMap: form.use_map,
            useAzureMap: form.use_azure_map,
            useGeonegociosMap: form.use_geonegocios_map,
            useForeignDeliveryBroker: form.use_foreign_delivery_broker,
            addressAttributes: this.convertFormAttributes(form.addressAttrs, AttributeTypes.ORDER_ADDRESSES),
            generalAttributes: this.convertFormAttributes(form.generalAttrs, AttributeTypes.CUSTOMER_INFO, order?.isACreatedOrder),
            additionalPhones: this.getArrayAdditionalPhones(order!.order_phones),
            changeStatusButtons: this.prepareChangeStatusButtons(form.buttons, form.virtual_buttons),
            fastChangeQueues: this.prepareFastChangeQueues(form.fastChangeQueue),
            conditionForFreeShipping: this.prepareAdditionalPrice(form.additionalPrice),
            activatePromoFrom: form.activate_promo_from,
            isCardPaymentAvailable: form.card_payment,
            maxRecallDate: form.max_recall_date,
            maskPhones: form.mask_phones,
            checkAddress: form.check_address,
            checkZip: form.check_zip,
            wholesaleLimit: !!form.wholesale_confirm && form.wholesale_confirm > 0 && form.wholesale_confirm || undefined,
            wholesale: form.wholesale,
            wholesaleEnabled: form.wholesale && !form.wholesale_confirm,
            statuses: this.prepareStatuses(form.buttons, form.virtual_buttons),
            allowGifts: form.allow_gifts || false,
            notUseCallAutomatization: form.not_use_auto_call_start,
            considerRemains: form?.consider_remains,
            approveWithoutShipping: form?.approve_without_shipping,
            enableRecallAfterCallSeconds: form.enable_recall_after_call_seconds,
            extraPrice: form.extra_price,
            maxGiftsCount: form.max_gifts_count,
            setZipByDistrict: form.set_zip_by_district,
            useAddressClassifier: form.use_address_classifier,
            ...(Array.isArray(form.structure) ? {} : { structure: form.structure }),
        };
    }

    public prepareSimilarOrder(order: ISimilarOrder): ISimilarOrderModelSnapshotIn {
        return {
            id: order.id,
            partner: order.partner,
            foreignId: order.foreign_id,
            status: order.status,
            customerFullName: order.customer_full_name,
            products: order.products,
            customerPhone: order.customer_phone,
            maskPhone: order.mask_phone,
            createdDate: order.created_at,
            isBuyout: order.is_buyout,
            type: order.type,
            statusCode: order.statusCode,
            customerComponents: order.secure.customer_components,
        };
    }

    public prepareOrderCommentItem(item: IOrderComment): IOrderCommentModelSnapshotIn {
        return {
            id: `${item.created_at}_${item.author}`,
            author: item.author,
            created_at: item.created_at,
            text: item.text,
        };
    }

    public prepareOrderCallHistoryItem(item: ICallHistory): ICallHistoryModelSnapshotIn {
        let startStatus: ICallHistoryStatusModelSnapshotIn | undefined;
        if (item.start_status) {
            startStatus = {
                id: String(item.start_status.id),
                name: item.start_status.name,
            };
        }

        let endStatus: ICallHistoryStatusModelSnapshotIn | undefined;
        if (item.end_status) {
            endStatus = {
                id: String(item.end_status.id),
                name: item.end_status.name,
            };
        }

        let user: ICallHistoryUserModelSnapshotIn | undefined;
        if (item.user) {
            user = {
                id: String(item.user.id),
                username: item.user.username,
            };
        }

        let provider: ICallHistoryProviderModelSnapshotIn | undefined;
        if (item.provider) {
            provider = {
                id: String(item.provider.id),
                name: item.provider.name,
            };
        }

        return {
            id: `CallHistory__${item.id}`,
            user,
            orderId: item.order_id,
            startTime: item.start_time,
            endTime: item.end_time,
            startStatus,
            endStatus,
            duration: item.duration,
            createdAt: item.created_at,
            updatedAt: item.updated_at,
            actionAddressTime: item.action_address_time,
            phone: item.phone,
            recordingFile: item.recording_file,
            disposition: item.disposition,
            billSec: item.billsec,
            userSip: item.user_sip,
            provider,
            isWebRTC: item.is_webrtc,
            pdd: typeof item.pdd === 'number' ? String(item.pdd) : typeof item.pdd === 'string' ? item.pdd : null,
            operatorRefreshed: item.operator_refreshed,
            sipCode: item.sipcode,
            predictiveTaskId: item.predictive_task_id,
            groupId: item.group_id,
            endCall: item.end_call,
        };
    }

    public prepareOrder(order: IOrder): ICurrentOrderSnapshotIn {
        let source: IOrderSourceModelSnapshotIn = {
            id: 0,
        };

        if (order.source) {
            source = {
                id: order.source.id,
                name: order.source.name,
                isFixedDeliveryPrice: order.source.is_fixed_delivery_price,
                isCardPaymentAvailable: order.source.card_payment,
                code: order.source.code,
                showQueueInOrder: get(this.currentCountry, 'show_queue_in_order') || false,
                hideOrderCreatedAt: order.source.hide_order_created_at,
            };
        }

        let orderType: IOrderTypeModelSnapshotIn | undefined;
        if (order.type?.name) {
            orderType = {
                id: Number(order.type.id),
                name: order.type.name,
            };
        }

        let lastQueue: IQueueTypeModelSnapshotIn | undefined;
        if (order.lastQueue && order.lastQueue.id) {
            lastQueue = {
                id: Number(order.lastQueue.id),
                name: order.lastQueue.name,
            };
        }

        return {
            id: order.id,
            customerMobile: order.customer_mobile ? getNumberFromString(order.customer_mobile) : null,
            createdAt: order.created_at,
            recallDate: order.recall_date,
            sourceUri: order.source_uri,
            customerEmail: order.customer_email,
            paymentCard: order.is_card_payment,
            customerFullName: order.customer_full_name,
            customerMiddleName: order.customer_middle_name,
            customerFirstName: order.customer_first_name,
            customerLastName: order.customer_last_name,
            customerAddress: order.customer_address,
            longitude: order.longitude,
            latitude: order.latitude,
            showCardPayment: Boolean(order.show_card_payment),
            orderType,
            source,
            lastQueue,
            partnerId: order.partner_id,
            countryId: order.country_id,
            globalAttempts: order.attempts || 0,
        };
    }

    public prepareCustomerData(_data: ICustomerComponents | string | null): Record<string, string> {
        let data = (typeof _data === 'string' ? JSON.parse(_data) : _data) as ICustomerComponents;

        if (!data) {
            data = {} as ICustomerComponents;
        }

        const {
            address = {},
            general = {},
        } = data;

        const isCustomerPhoneOfComponent = general[AttrsKey.CUSTOMER_PHONE] && general[AttrsKey.CUSTOMER_PHONE].length;
        const isCustomerMainPhone = this.customerMainPhone && this.customerMainPhone.length;

        if (!isCustomerPhoneOfComponent) {
            if (isCustomerMainPhone && this.customerMainPhone) {
                general[AttrsKey.CUSTOMER_PHONE] = this.customerMainPhone;
            } else {
                general[AttrsKey.CUSTOMER_PHONE] = '';
            }
        }

        return { ...general, ...address };
    }
}


export default OrderPrepareMetadata;
