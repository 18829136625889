import React, { useCallback, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { observer } from 'mobx-react';
import { useInstances } from 'react-ioc';

import Spinner from '@UIElements/Spinner';
import I18NService from '@services/I18NService';
import { Store } from '@store/store';
import OperatorIconSvg from '@components/main/order-page/OperatorIconSvg/OperatorIconSvg';
import AlertIconSvg from '@components/resources/SVG/Alert/AlertIconSvg';
import Button from '@UIElements/Button';
import ProccessIconSvg from '@components/main/order-page/ProccessIconSvg/ProccessIconSvg';
import { OrderService, UserService, PostProcessingService } from '@/app/services';
import NotificationPollingService from '@services/NotificationPollingService';
import { UserModeEnum } from '@models/mobx-state-tree/user.model';
import DevicesService from '@core/services/DevicesService';
import getLinkToAccessMicrophone from '@/env/getLinkToAccessTheMicrophone';
import DivWithDangerouslySetInnerHTML from '@/app/ui-elements/DivWithDangerouslySetInnerHTML';
import { autoCall } from '@core/constants/autoCall';
import LocalStorageService from '@core/services/LocalStorageService';
import ResizeControl from '@components/main/resize-control/resize-control';
import { EnumTypeOrder } from '@components/main/order-page/call-panel/call-widget/CallWidget';
import { PARAMS_FOR_AUX_EVENTS } from '@core/constants/local-storage';


const HIDE_NOTIFICATION_ON_SCROLL_PX = 10;

interface IOrderFormProps {
    children: JSX.Element;
    notifyWidget?: JSX.Element;
    inactivity?: JSX.Element;
    floatingCallButton?: JSX.Element;
}

const scrollBarsStyle = { height: '100%' };

const OrderForm = ({
    children,
    inactivity,
    floatingCallButton,
}: IOrderFormProps): JSX.Element => {
    const [
        { t },
        {
            notificationReceivedFromServer,
            notificationWithFeedbackToView,
            removeNotification,
            inactivityTimer: {
                startTimer,
                stopAllTimers,
            },
            ui: {
                orderPage: {
                    setNotificationHiddenOnScroll,
                    notificationHiddenOnScroll,
                },
            },
            currentUser: {
                haveSipPlatinumAccountCredential,
                predictiveAutocall,
                mode,
                isReady: isReadyToAcceptOrder,
                setIsReady,
                setUnreadyAfterSave,
                selectedAuxStatus,
                setSelectedAuxStatus,
            },
            currentOrder: {
                isEmptyCurrentOrder,
                isACreatedOrder,
                id: orderId,
                orderType,
                postProcessing,
                countryId,
                lastQueue,
                globalAttempts,
                sessionCallCount,
            },
        },
        orderService,
        nPolling,
        {
            isGiveBrowserAccessToYourMicroError,
            isConnectMicrophoneToDeviceError,
            hardwareErrorMessage,
            isPermissionStatePrompt,
        },
        localStorageService,
        postProcessingService,
        userService,
    ] = useInstances(
        I18NService,
        Store,
        OrderService,
        NotificationPollingService,
        DevicesService,
        LocalStorageService,
        PostProcessingService,
        UserService,
    );

    const [top, setTop] = useState<number>(0);
    const internetConection = localStorage.getItem('no internet conection');
    const autoCallValue = localStorageService.getItem(autoCall);

    useEffect(() => {
        if (orderId !== 0) {
            const data = {
                orderId,
                countryId,
                lastQueueId: lastQueue?.id,
                globalAttempts: globalAttempts + sessionCallCount,
                attempts: sessionCallCount,
            };
            localStorageService.setItem(PARAMS_FOR_AUX_EVENTS, data);
        }

        if (!isACreatedOrder && mode !== UserModeEnum.PROGRESSIVE) {
            if (postProcessing) {
                postProcessingService.checkEndingModalTimerAfterReload();
            }
            startTimer(postProcessing);
        } else {
            stopAllTimers();
        }

    }, [orderId, isACreatedOrder]);

    const onClick = useCallback(() => {
        setSelectedAuxStatus(null);
        setIsReady(true);
        setUnreadyAfterSave(false);
    }, [setIsReady]);

    const onScrollFrame = useCallback((values) => {
        const delta = values.scrollTop - top;

        if (delta > HIDE_NOTIFICATION_ON_SCROLL_PX) {
            setNotificationHiddenOnScroll(true);
            setTop(values.scrollTop);
        }

        if (delta < -HIDE_NOTIFICATION_ON_SCROLL_PX) {
            setNotificationHiddenOnScroll(false);
            setTop(values.scrollTop);
        }
    }, [setNotificationHiddenOnScroll, setTop, top, notificationHiddenOnScroll]);

    const [response, setResponse] = useState<string>('');
    const [pending, setPending] = useState<boolean>(false);

    const onResponseChange: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void = useCallback((e: React.SyntheticEvent<HTMLTextAreaElement>) => {
        setResponse(e.currentTarget.value);
    }, [setResponse]);

    const onFeedBack: () => Promise<void> = useCallback(async () => {
        if (!notificationWithFeedbackToView) {
            return;
        }

        setPending(true);

        try {
            const result = await orderService.setNotificationSuccess(
                parseInt(notificationWithFeedbackToView.id, 10),
                response,
            );

            if (result) {
                setResponse('');
                removeNotification(notificationWithFeedbackToView.id);
                // Чтобы следуюзщее уведомление получили прямо сейчас.
                nPolling.reinitialize();
            }

            if (selectedAuxStatus && isEmptyCurrentOrder) {
                await userService.changeAuxStatus(selectedAuxStatus);
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        } finally {
            setPending(false);
        }
    }, [setPending, notificationWithFeedbackToView, response]);

    const onWindowReload = useCallback(
        () => {
            window.location.reload();
        }, [],
    );

    const toInstruction = useCallback(
        () => window.open(getLinkToAccessMicrophone(), '_blank'),
        [],
    );

    if (isEmptyCurrentOrder && predictiveAutocall) {
        return (
            <div className="status-unready-polling-notifier">
                <div className="status-unready-polling-notifier__image">
                    <AlertIconSvg />
                </div>
                <div className="status-unready-polling-notifier__message">
                    {t(
                        'Включен режим предиктивного звонка. Система пока что не умеет работать в этом режиме. Свяжитесь с поддержкой для решения проблемы.',
                        'Predictive autocall have been turned on. New UI CRM cannot work in this mode at this moment. Please contact support.',
                    )}
                </div>
            </div>
        );
    }

    if (!haveSipPlatinumAccountCredential && !autoCallValue) {
        return (
            <div className="status-unready-polling-notifier">
                <div className="status-unready-polling-notifier__image">
                    <AlertIconSvg />
                </div>
                <div className="status-unready-polling-notifier__message">
                    {t(
                        'Отсутствуют данные о SIP аккаунте',
                        'Missing SIP account data',
                    )}
                </div>
            </div>
        );
    }

    if (isPermissionStatePrompt) {
        return (
            <div className="status-unready-polling-notifier">
                <div className="status-unready-polling-notifier__message">
                    {t(
                        'Для обработки заказа требуется доступ к микрофону.',
                        'Access to a microphone is required to process the order.',
                    )}
                </div>
            </div>
        );
    }

    if (notificationWithFeedbackToView) {

        return (
            <div className="status-unready-polling-notifier">
                <div className="status-unready-polling-notifier__image">
                    <ProccessIconSvg />
                </div>
                <div className="status-unready-polling-notifier__message">
                    <h3>{t('Время истекло', 'Time has passed.')}</h3>
                    <DivWithDangerouslySetInnerHTML
                        className="message"
                        html={notificationWithFeedbackToView.message}
                    />
                    <textarea
                        data-cy="status-unready-polling-notifier__message--input"
                        disabled={pending}
                        value={response}
                        onChange={onResponseChange}
                        placeholder={t('Введите комментарий', 'Enter commentary')}
                    />
                </div>
                <div className="send-button-root">
                    <Button
                        className="button"
                        disabled={pending || !response.length}
                        variant="7"
                        onClick={onFeedBack}
                        size="1"
                        text={t('Отправить', 'Send')}
                        width="128px"
                        dataCyValue="status-unready-polling-notifier__sendBtn"
                    />
                </div>
            </div>
        );
    }

    if (isEmptyCurrentOrder) {
        if (hardwareErrorMessage) {
            return (
                <div className="status-unready-polling-notifier">
                    <div className="status-unready-polling-notifier__image">
                        <OperatorIconSvg />
                    </div>
                    <div className="status-unready-polling-notifier__message">
                        {hardwareErrorMessage}
                    </div>
                    {isGiveBrowserAccessToYourMicroError && (
                        <div className="send-button-root">
                            <Button
                                className="button"
                                variant="7"
                                onClick={toInstruction}
                                size="1"
                                text={t('Как это сделать', 'How to do it')}
                                padding={'16px'}
                                dataCyValue="status-unready-polling-notifier__link"
                            />
                        </div>
                    )}
                    {isConnectMicrophoneToDeviceError && (
                        <div className="send-button-root">
                            <Button
                                className="button"
                                variant="7"
                                onClick={onWindowReload}
                                size="1"
                                text={t('Перезагрузить', 'Reload')}
                                width="128px"
                                dataCyValue="status-unready-polling-notifier__reload"
                            />
                        </div>
                    )}
                </div>
            );
        }

        /**
         * TODO: Сейчас не показываем этот спиннер: утраченный функционал
         * Стоит подумать про получение нотификаций в рамках транзакции
         * в интервале между получениями заказов
         */
        if (!notificationReceivedFromServer) {
            return (
                <Spinner className="preloader" text={t('Получаем уведомления', 'Receiving notifications')} />
            );
        }

        if (isReadyToAcceptOrder && mode !== UserModeEnum.PROGRESSIVE && (!selectedAuxStatus || (selectedAuxStatus && selectedAuxStatus.orderGet))) {
            return (
                <Spinner className="preloader" text={t('Поиск нового заказа', 'Searching for a new order')} />
            );
        }

        if (internetConection) {
            return (
                <div className="status-unready-polling-notifier">
                    <div className="status-unready-polling-notifier__image">
                        <OperatorIconSvg />
                    </div>
                    <div className="status-unready-polling-notifier__message">
                        {t(
                            'Вы были принудительно переключены в режим "не готов" из за плохого качества интернета',
                            'You were forcibly put on "not ready" due to poor internet quality',
                        )}
                    </div>
                    <Button
                        variant="2"
                        className="with-padding"
                        size="1"
                        text={t('Готов для следующего звонка', 'Active to next call')}
                        width="auto"
                        onClick={onClick}
                    />
                </div>
            );
        }
        if (!isReadyToAcceptOrder || (selectedAuxStatus && !selectedAuxStatus.orderGet)) {
            return (
                <div className="status-unready-polling-notifier">
                    <div className="status-unready-polling-notifier__image">
                        <OperatorIconSvg />
                    </div>
                    <div className="status-unready-polling-notifier__message">
                        {t(
                            'Чтобы принять заказ, смените статус на Готов для звонка',
                            'To receive an order, change the status to Active to Call',
                        )}
                    </div>
                    <Button
                        variant="2"
                        className="with-padding"
                        size="1"
                        text={t('Готов для следующего звонка', 'Active to next call')}
                        width="auto"
                        onClick={onClick}
                    />
                </div>
            );
        }
    }

    return (
        <>
            <div className="order-page">
                {floatingCallButton}
                {inactivity}
                <Scrollbars
                    className="order-page-form-scroll-container"
                    key={orderId}
                    style={scrollBarsStyle}
                    hideTracksWhenNotNeeded={true}
                    universal={false}
                    onScrollFrame={onScrollFrame}
                >
                    {children}
                </Scrollbars>
            </div>
            {orderType.id !== EnumTypeOrder.CREATED && !isEmptyCurrentOrder && <ResizeControl/>}
        </>
    );
};


export default observer(OrderForm);
