import {
    applySnapshot, Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import sortBy from 'lodash/sortBy';

import { FormDelivery } from '@models/mobx-state-tree/formDelivery.model';
import { CurrentOrder } from '@models/mobx-state-tree/currentOrder.model';
import { User } from '@models/mobx-state-tree/user.model';
import {
    DEFAULT_CURRENT_ORDER,
    DEFAULT_INACTIVITY_TIMER,
    DEFAULT_USER,
    DEFAULT_UI_STATE,
} from '@core/constants/defaultStoreItems';
import { OperatorInactivityNotificationTimersModel } from '@models/mobx-state-tree/OperatorInactivityNotificationTimersModel';
import { INotificationModel, NotificationModel } from '@models/mobx-state-tree/Notification.model';
import { RootUIStateModel } from '@models/mobx-state-tree/ui/RootUIStateModel';
import { IOrderCreationModelSnapshotIn, OrderCreation } from '@models/mobx-state-tree/orderCreation.model';
import { ResponsiveModel } from '@models/mobx-state-tree/responsive.model';
import { IOrderViewModel, IOrderViewModelSnapshotIn, OrderViewModel } from '@models/mobx-state-tree/orderView.model';

import { IRecallModel, RecallModel } from '@models/mobx-state-tree/recalls.model';
import { Locations } from '@core/models/locations';
import { IVerifierModel, VerifierModel } from '@models/mobx-state-tree/verifier.model';

const sortNotifications = (array: INotificationModel[]) => sortBy(array, [(n: INotificationModel) => parseInt(n.id, 10)]);

export const RootStore = t
    .model({
        ui: t.optional(RootUIStateModel, {}),
        orderCreation: t.optional(OrderCreation, {}),
        currentUser: t.optional(User, DEFAULT_USER),
        currentOrder: t.optional(CurrentOrder, DEFAULT_CURRENT_ORDER),
        formDelivery: t.optional(FormDelivery, {}),
        inactivityTimer: t.optional(OperatorInactivityNotificationTimersModel, DEFAULT_INACTIVITY_TIMER),
        notifications: t.map(NotificationModel),
        notificationReceivedFromServer: t.optional(t.boolean, false), // Метка по которой понимаем что сетевой запрос на уведомления был
        prevSavedOrderId: t.maybeNull(t.number), // Последний заказ который сохранил оператор
        responsive: t.optional(ResponsiveModel, {}),
        shouldGoToReadyIfNoNotificationsWithFeedback: t.optional(t.boolean, false),
        orderToView: t.optional(t.array(OrderViewModel), []),
        /**
         * Номер разблокированного заказа \
         * Получаем его по websocket с бэка \
         * Если unblockedOrderId === currentOrder.id, \
         * то очищаем заказ, переводим в unready, показываем уведомление
         */
        unblockedOrderId: t.maybeNull(t.number),
        recalls: t.map(RecallModel),
        verifiers: t.map(VerifierModel),
        currentTab: t.optional(t.string, Locations.MAIN),
    })
    .views(((self) => ({
        orderToViewForComponent(): IOrderViewModel[] {
            return self.orderToView;
        },
        get notificationsWithFeedback(): INotificationModel[] | null {
            if (!self.notifications.size) {
                return null;
            }

            const sorted = sortNotifications([...self.notifications.values()]);

            return sorted.filter((item: INotificationModel) => item.isFeedback);
        },
        get notificationsWithoutFeedback(): INotificationModel[] | null {
            if (!self.notifications.size) {
                return null;
            }

            const sorted = sortNotifications([...self.notifications.values()]);

            return sorted.filter((item: INotificationModel) => !item.isFeedback);
        },
    })))
    .views(((self) => ({
        get hasNotificationWithFeedBack(): boolean {
            if (!self.notificationsWithFeedback) {
                return false;
            }

            return self.notificationsWithFeedback.length > 0;
        },
        get hasNotificationWithoutFeedback(): boolean {
            if (!self.notificationsWithoutFeedback) {
                return false;
            }

            return self.notificationsWithoutFeedback.length > 0;
        },
    })))
    .views(((self) => ({
        get notificationWithFeedbackToView(): INotificationModel | null {
            if (!self.notificationsWithFeedback) {
                return null;
            }

            return self.notificationsWithFeedback[0];
        },
        get notificationWithoutFeedbackToView(): INotificationModel | null {
            if (!self.notificationsWithoutFeedback) {
                return null;
            }

            return self.notificationsWithoutFeedback[0];
        },
        get hasNotifications(): number | null {
            if (!self.notifications.size) {
                return null;
            }

            return self.notifications.size;
        },
    })))
    .actions(((self) => ({
        /**
         * Установить номер заказа, который был разблокирован
         */
        setUnblockedOrderId(value: number | null) {
            self.unblockedOrderId = value;
        },

        clearCurrentOrder(): void {
            applySnapshot(self.currentOrder, DEFAULT_CURRENT_ORDER);
            self.inactivityTimer.stopAllTimers();
            applySnapshot(self.inactivityTimer, DEFAULT_INACTIVITY_TIMER);
        },
        setNotificationReceivedFromServer(value: boolean): void {
            self.notificationReceivedFromServer = value;
        },
        addNotification(notification: INotificationModel): void {
            self.notifications.put(notification);
        },
        clearVerifiers(): void {
            self.verifiers.clear();
        },
        clearNotifications(): void {
            self.notifications.clear();
        },
        removeNotification(notificationId: string): void {
            self.notifications.delete(notificationId);
        },
        setOrderCreationModel(model: IOrderCreationModelSnapshotIn): void {
            applySnapshot(self.orderCreation, model);
            self.orderCreation.setDefaultSelectedSourceIdAfterCreate();
        },
        clearOrderCreationModel(): void {
            applySnapshot(self.orderCreation, {});
        },
        setPrevSavedOrderId(orderId: number | null): void {
            self.prevSavedOrderId = orderId;
        },
        resetUIState(): void {
            applySnapshot(self.ui, DEFAULT_UI_STATE);
        },
        setShouldGoToReadyIfNoNotificationsWithFeedback(value: boolean): void {
            self.shouldGoToReadyIfNoNotificationsWithFeedback = value;
        },
        clearOrderView(): void {
            applySnapshot(self.orderToView, []);
        },
        addOrderForAView(orderView: IOrderViewModelSnapshotIn): void {
            self.orderToView.push(orderView);
        },
        setCurrentTab(tab: string): void {
            self.currentTab = tab;
        },
    })))
    .views((self) => ({
        get recallsList(): IRecallModel[] {
            return [...self.recalls.values()];
        },
        get verifiersList(): IVerifierModel[] {
            return [...self.verifiers.values()];
        },
    }));


export interface IRootStoreModel extends Instance<typeof RootStore> {}
export interface IRootStoreModelSnapshotIn extends SnapshotIn<typeof RootStore> {}
export interface IRootStoreModelSnapshotOut extends SnapshotOut<typeof RootStore> {}
