import { IQueue } from '@interfaces/queue.interface';
import { AttrsKey, IForm } from '@interfaces/form.interface';
import { ICustomerComponents } from '@interfaces/customerComponents.interface';
import { IPartnerProduct } from '@api/partner-api-service/models';
import { ICommunicationQualityItem } from '@api/support-api-service/model';
import { ICallHistory } from '@api/call-api-service/model';
import { IOldPromotionData } from '@api/old-promotion-api-service/models';


export interface IOrderComment {
    author: string;
    text: string;
    created_at?: number;
}

export interface IDelivery {
    id: number;
    name: string;
    price: number;
    have_express: boolean;
    country: string;
    min_delivery_date: string;
    max_delivery_date: string;
    limit_exceeded_days: Array<string>;
    work_days: Array<number>;
    partner_id: number;
}

export interface IDeliveryProducts {
    id: number;
    price: number;
    quantity: number;
    gift: number;
    promo: number;
}

export interface IGetDeliveryListParams {
    id: number;
    customerZip: string;
    customerDistrict: string;
    products: IDeliveryProducts[];
    useForeignDeliveryBroker: boolean | null;
    countryId: number;
    partnerId: number;
}

interface IOrderType {
    id: number;
    name: string;
}

interface IOrderShipping {
    id: number;
    name: string;
}

export interface IOrderPartner {
    id: number;
    name: string;
}

interface IOrderStatusInfo {
    id: number;
    name: string;
}

export interface ILanguageResource {
    id: number;
    name: string;
    locale: string;
    icon: string;
}

export interface IRecallLimits {
    country_id: number;
    limit_seconds: number;
    limit_time_type: number;
    order_type_id: number;
}

export interface ICountryDataBase {
    id: number;
    name: string;
    slug: string;
    char_code: string;
    timezone_id: number | null;
    currency_id: number | null;
    language_id: number | null;
    provider_id: number;
    language: ILanguageResource;
    block_time_strategy: number;
    helpline_phone: string;
    order_hold_verification: boolean;
    klader_in_all_string: boolean;
    use_modified_deliveries: boolean;
    use_api_kladr_country: boolean;
    use_check_price: boolean;
    min_time_wait_call: number;
    /**
     * Лимит PDD (Post Dial Delay)
     */
    webrtc_pdd_limit: number | null;
    /**
     * Принудительно прерывать соединение, если невозможно соединиться более - X секунд
     */
    pdd_limit_end_call: boolean;
    /**
     * Автоматически инициировать следующий звонок если оператор 'готов'
     */
    start_call_automatic: boolean;
    /**
     * Количество звонков автонабора
     */
    wsca_count: number;
    /**
     * Интервал между звонками автонабора в секундах
     */
    wsca_interval: number;
    noanswer_after_sec: number;
    /**
     * Назначить повторный звонок через N мин.
     */
    recall_to_min: number | null;
    /**
     * Диапазон выбора времени нахождения заказа в статусе RECALL
     */
    recall_limit_seconds: number | null;
    status_automatization_active: boolean;
    /**
     * Назначить статус Недозвон при сбросе
     */
    no_answer_if_busy: boolean;
    /**
     * Уведомлять оператора об отсутствии товара на складе
     */
    stock_alerting_enabled: boolean; // Уведомлять оператора об отсутствии товара на складе
    log_active_call: boolean;
    hide_orders_history: boolean;
    form_fill_timer: number;
    show_queue_in_order: boolean;
    phone_editing_range: number | null;
    view_form_fill_timer?: number | null;
    save_form_filter_short_call: number | null;
    save_form_filter_long_call: number | null;
    save_form_filter_duration_call: number | null;
    accept_time_limit_for_block_noanswer: number | null;
    recall_limits: IRecallLimits[] | null;
}

export interface IOrderProductSet { // Данные выбранного изменяемого товара
    id: number; // ID выбранного изменяемого товара
    quantity: number;
    product_id: number; // ID из таблицы product
}

export interface IOrderProduct {
    id: number;
    /**
     * TODO: если null, значит не подарок?)
     */
    gift: boolean | null;
    promo: boolean;
    /**
     * Кол-во продукта в заказе
     */
    quantity: number;
    price: number;
    /**
     * Данные выбранного изменяемого товара
     */
    set: IOrderProductSet[];
    /**
     * Данные продукта
     */
    product: IOrderProductPartnerData;
    /**
     * ID примененной акции
     */
    package_id: number | null;
    /**
     * Примененная акция, если null значит её нет
     */
    package: IOldPromotionData | null;
    /**
     * Товар является основным в заказе
     */
    main: boolean;
    /**
     * Товар пришел с лидом
     */
    with_lead: boolean;
    /**
     * ID примененного конструктора акций
     */
    promotion_builder_id: number;
    /**
     * Запретить изменять основной товар
     */
    editable: boolean;
}

export interface IOrderProductPartnerData { // Данные продукта
    id: number; // ИД продукта в таблице product (это внутренняя таблица, в которой бэк хранит связь с партнерским продуктом в таблице partner_product)
    name: string;
    partnerProduct: IOrderPartnerProductData | null; // продукт в таблице partner_product
}

export interface IOrderPartnerProductData { // Данные партнерского товара
    id: number;
    price: number | string; // Цена (если тип цены fixed, то Int, Float или String)
    description: string | null;
    translation: string | null;
    image: string | null;
    weight: number | null;
    height: number | null;
    length: number | null;
    quantity: number; // кол-во на складе
    out_of_stock_message: string; // если quantity 0, то можно использовать этот атрибут для обозначения того, что товара нет в наличии (должно быть переведено на язык пользователя)
    is_dynamic_set: boolean;
    is_product_set: boolean;
    products: IPartnerProductDynamicSetData[] | IPartnerProductStaticSetData[] | null; // Состав набора
}

export interface IPartnerProductDynamicSetData { // Состав динамического набора
    id: number; // ID партнерского товара
    name: string;
    product_id: number; // ID товара в product (это внутренняя таблица, в которой бэк хранит связь с партнерским продуктом в таблице partner_product)
    quantity: number; // Количество товара в составе набора
    is_packing: boolean;
    alternatives: IPartnerProductSetAlternativesData[]; // Даные об альтернативах данного товара в наборе
}

export interface IPartnerProductStaticSetData { // Состав статического набора
    id: number; // ID партнерского товара
    name: string;
    product_id: number; // ID товара в product (это внутренняя таблица, в которой бэк хранит связь с партнерским продуктом в таблице partner_product)
    quantity: number; // Количество товара в составе набора
    is_packing: boolean;
}

export interface IPartnerProductSetAlternativesData { // Даные об альтернативах данного товара в наборе
    id: number; // ID партнерского товара
    name: string;
    product_id: number; // ID товара в product (это внутренняя таблица, в которой бэк хранит связь с партнерским продуктом в таблице partner_product)
}

export interface IOrderViewProduct {
    id: number;
    gift: boolean | null;
    promo: boolean | null;
    product: IOrderProductPartnerData;
    quantity: number;
    price: number;
    cost: number | null;
}

export interface IOrderSource {
    card_payment: boolean;
    code: number;
    hide_order_created_at: boolean;
    id: number;
    is_fixed_delivery_price: boolean;
    name: string;
}

export interface ISimilarOrder {
    id: number;
    partner: string;
    foreign_id: number;
    status: string;
    customer_full_name: string;
    products: string;
    customer_phone: string;
    mask_phone: boolean;
    created_at: number;
    is_buyout: boolean | null;
    type: IOrderType;
    statusCode: number | null;
    secure: ISecureData;
}

export interface IOrderCurrency {
    id: number;
    char_code: string;
}

export interface IPredictiveTaskData { // Данные предиктивного таска
    id: number;
    zorra_task_id: number;
    order_id: number;
    phone: string;
    created_at: number;
    attempts: number;
    priority: number;
    call_history: ICallHistory[] | null;
}

export interface ITypeSource {
    id: number;
    name: string;
    description: string | null;
}

export interface IOrder {
    id: number;
    country_id: number;
    type_id: number | null;
    status: number;
    sub_status: string | null;
    partner_id: number;
    customer_phone: string;
    customer_mobile: string | null;
    customer_address: string | null;
    customer_components: ICustomerComponents | string | null; // TODO Приходит объект в строке
    init_price: number | null;
    final_price: number | null;
    form_id: number | null;
    recall_date: string | null;
    customer_first_name: string | null;
    customer_last_name: string | null;
    customer_middle_name: string | null;
    shipping_id: number | null;
    customer_email: string | null;
    ordered_at: number | null;
    order_phones: string | null;
    language_id: number | null;
    finished: boolean | null;
    order_comment: string | null;
    customer_full_name: string | null;
    delivery_from: number | null;
    delivery_to: number | null;
    latitude: string | null;
    limit_calls: number | null;
    longitude: string | null;
    google_zip: string | null;
    promotion_builder_id: string | number | null;
    is_card_payment: boolean | null;
    show_card_payment: boolean | null;
    discount_type: string | number | null;
    discount_value: string | number | null;
    date_first_call: number | null;
    last_queue_id: number | null;
    prevent_queue_id: number | null;
    type: IOrderType | null;
    shipping: IOrderShipping | null;
    shipping_price: number | null;
    source_uri: string | null;
    created_at: number | null;
    country: ICountryDataBase;
    partner: IOrderPartner;
    form: IForm | null;
    statusInfo: IOrderStatusInfo;
    subStatusInfo: ISubStatusInfo[];
    orderProducts: IOrderProduct[];
    lastQueue: IQueue | null;
    preventQueue: IQueue | null;
    source: IOrderSource | null;
    typeSource: ITypeSource | null;
    foreign_id: number | null;
    updated_at: number | null;
    attempts: number | null;
    confirm_address: number | null;
    currency: IOrderCurrency | null;
    predictive_task: IPredictiveTaskData | null; // Данные предиктивного таска
    callHistory: ICallHistory[]; // todo: зачем тут этот атрибут?
    success_phone: string | null;
    secure: ISecureData;
    similar_count: number;
}

export interface IOrderView {
    id: number;
    currency: IOrderCurrency | null;
    country_id: number;
    type_id: number | null;
    status: number;
    sub_status: string | null;
    partner_id: number;
    customer_phone: string;
    customer_mobile: string | null;
    customer_address: string | null;
    init_price: number | null;
    final_price: number | null;
    form_id: number | null;
    recall_date: string | null;
    customer_first_name: string | null;
    customer_last_name: string | null;
    customer_middle_name: string | null;
    shipping_id: number | null;
    customer_email: string | null;
    ordered_at: number | null;
    order_phones: string | null;
    language_id: number | null;
    finished: boolean | null;
    show_card_payment: boolean | null;
    order_comment: string | null;
    customer_full_name: string | null;
    delivery_from: number | null;
    delivery_to: number | null;
    latitude: string | null;
    longitude: string | null;
    google_zip: string | null;
    promotion_builder_id: string | number | null;
    is_card_payment: boolean | null;
    discount_type: string | number | null;
    discount_value: string | number | null;
    date_first_call: number | null;
    last_queue_id: number | null;
    prevent_queue_id: number | null;
    source_uri: string | null;
    created_at: number | null;
    shipping_price: number | null;
    lastQueue: IQueue | null;
    preventQueue: IQueue | null;
    callHistory: ICallHistory[];
    form: IForm | null;
    customer_components: string; // TODO Приходит объект в строке
    type: IOrderType;
    shipping: IOrderShipping | null;
    country: ICountryDataBase;
    partner: IOrderPartner;
    statusInfo: IOrderStatusInfo;
    source: IOrderSource | null;
    orderProducts: IOrderViewProduct[];
    subStatusInfo: ISubStatusInfo[];
}

export interface ISubStatus {
    id: number;
    name: string;
    code: string;
}

export interface ISubStatusInfo {
    status: number;
    sub_statuses: ISubStatus[];
}

export interface IOrderForService extends IOrder {
    post_processing: boolean;
    similarOrders: ISimilarOrder[];
    partnerProducts: IPartnerProduct[];
    comments: IOrderComment[];
    communicationQualityList: ICommunicationQualityItem[];
    isACreatedOrder?: boolean;
    smsListFromErp: TSmsListFromErp | null;
}

export interface IOrderProductUpdate {
    id?: number;
    product_id: number;
    price: number;
    quantity: number;
    gift: boolean;
    promo: boolean;
    set?: ISetCompositionRecord[];
    package_id?: number;
    promotion_builder_id?: number;
}

export interface ISetCompositionRecord { // массив товаров в динамическом наборе
    id: number; // ID товара в partner_product
    quantity: number; // Количество товара
    product_id: number; // ID товара в product
}

export interface IOrderUpdate {
    id: number; // Номер заказа
    status: number; // Статус заказа (2 - recall, 3-no answer, 4-approve,5-reject, 6-trash) актуальный список можно запросить у разработчиков. (статус новый - не используется)
    recall_date?: string; // Дата перезвона (при статусе заказа 2 - recall) Формат: YYYY/MM/DD HH:ii:ss
    products: IOrderProductUpdate[]; // Товары заказа. [id, product_id, price, gift, promo]. При обновлении заказа должен присутствовать хотя бы 1 товар. Формат: id - foreign id на order_product (если он не указан - значит товар добавляется в заказ), price - стоимость (double), quantity - количество (integer), product_id - foreign id на partner_product, gift - true если подарок (price = 0, bool), promo - промо товар (цена отличная от цены справочника, bool)
    general: Record<AttrsKey, string | number | null>; // Данные клиента. Определяются полями PartnerForm заказа (form_id) в основном это customer_full_name, customer_email, customer_phone, customer_mobile, customer_age. Обязательность и тип полей определяется атрибутами формы заказа.
    address: Record<AttrsKey, string>; // Данные адреса. Определяются полями PartnerForm заказа (form_id). Набор и обязательность полей зависит от страны.
    additional_phones?: string[]; // (Необязательный) Дополнительные номера телефонов к заказу (помимо customer_phone, customer_mobile)
    sub_status?: number; // (Необязательный) Дополнительный статус заказа (используется совместно 5-reject, 6-trash + для заказов типа проверки недоставки) актуальный список можно запросить у разработчиков)
    is_card_payment?: boolean; // (Необязательный) Признак оплаты заказа картой.
    promotion_builder_id?: number; // (Необязательный) foreign id на promotion_builder. Идентификатор акции (Конструктор акций), которая была применена к заказу.
    latitude?: string; // (Необязательный) Географические координаты: широта.
    longitude?: string; // (Необязательный) Географические координаты: долгота.
    confirm_address?: number; // (Необязательный) Признак подтверждения адреса (0-default, 1-нуждается в проверке адреса, 2-адрес подтвержден, 3-заказ отправлен без подтверждения адреса)
    shipping_id?: number; // (Необязательный) foreign id на partner_shipping(shipping_id) Курьерская служба.
    delivery_from?: string; // (Необязательный) Дата, время доставки от. Формат: YYYY/MM/DD HH:ii:ss
    delivery_to?: string; // (Необязательный) Дата, время доставки до. Формат: YYYY/MM/DD HH:ii:ss
    price_shipping_id?: number; // (Необязательный) foreign id на price_shipping. Тариф стоимости доставки (забытый, но нужный функционал при отключенном брокере).
    comment?: string; // (Необязательный) Комментарий заказа (максимальная длина - 1000 символов).
    call_history_group_id?: string; // (Необязательный) Идентификатор группы попыток звонков.
    shipping_price?: number;
}

export interface ISaveAddressAccessTimeData {
}

export interface IGoogleAddressData {
    zipCode?: string; // Адрес может и нашли но у него нет zipCode
}

export interface INagiosAddressData {
    address?: INagiosAddressDataAddress;
}

export interface INagiosAddressDataAddress {
    postalCode?: string;
}

export interface INagiosAddressArgs {
    address: string;
    ubigeo: string;
    country: string;
}

export interface IUpdateOrderProductPriceArgs {
    productId: number;
    price: number;
}

export interface IReleaseOrderArgs {
    orderId: number;
}

export interface IUpdateOrderProductPriceData {
}

export interface ICreateOrder {
    form: any;
    order: IOrder;
}

export type TOrderStatus = {
    readonly id: OrderStatusesERPEnum;
    readonly name: string;
};

export type TERPOrderStatus = Record<string, TOrderStatus>;

export enum OrderStatusesEnum {
    CREATED = 1,
    RECALL = 2,
    NO_ANSWER = 3,
    APPROVE = 4,
    REJECT = 5,
    TRASH = 6,
    TECH = 9,
    CRASH = 11,
}

export enum OrderStatusesTypeERPEnum {
    UNDEFINED,
    INITIAL_STATUS,
    INTERMEDIATE_STATUS,
    FINAL_STATUS,
}

export enum OrderStatusesERPEnum {
    ORDER_IS_MISSING_FROM_ERP = 0,
    ORDER_CREATED_WITH_A_LONG_FORM = 1,
    ORDER_IS_CREATED_WITH_A_SHORT_FORM = 2,
    SENT_TO_CALL_CENTER_FOR_A_CALL = 3,
    CHIME_CALL_CENTER = 4,
    NO_ANSWER_CALL_CENTER = 5,
    APPROVED_CALL_CENTER = 6,
    DIVERTED_CALL_CENTER = 7,
    ACCEPTED_PENDING_SHEET_GENERATION = 8,
    SHEET_GENERATED_LABELS_PENDING = 9,
    LABELS_HAVE_BEEN_CREATED = 10,
    LABELS_ARE_AFFIXED = 11,
    APPROVED_COURIER = 12,
    LEFT_WAREHOUSE = 13,
    PAID_FOR_DELIVERED = 14,
    REDEMPTION_COURIER = 15,
    REJECTION_COURIER = 16,
    RETURNED_TO_SENDER_COURIER = 17,
    MONEY_RECEIVED_FINANCE = 18,
    CANCEL_COURIER = 19,
    LEFT_OUR_WAREHOUSE_ONCE_UPON_A_TIME = 20,
    FORWARDED_COURIER = 21,
    LEFT_THEIR_WAREHOUSE = 22,
    REJECTED_LOGISTICS = 23,
    DELAYED_COURIER = 24,
    TRASH_CALL_CENTER = 25,
    DOUBLE_CALL_CENTER = 26,
    FORWARDED_FOR_RETURN_COURIER = 27,
    ACCEPTED_LOGISTICS = 28,
    LOST_COURIER = 29,
    ORDER_IS_CREATED_IN_CALL_CENTER_FROM_INCOMING_QUEUE = 30,
    SENT_FOR_DELIVERY_COURIER = 31,
    POSTPONED_LOGISTICS = 32,
    AUTOTRASH_ORDERING = 33,
    RECLAMATION = 34,
    AUTOTRASH_ORDER_BY_DUPLICATE = 35,
    CUSTOMER_REFUSED = 36,
    SHEET_SENT_COURIER = 37,
    SHEET_ACCEPTED_COURIER = 38,
    ON_REVIEW_CALL_CENTER = 39,
    WRITTEN_OF_FINANCE = 40,
}

export interface ISmsFromErp {
    id: number;
    phone: string;
    template: string;
    sms_text: string;
    status: string;
    error: string | null;
    created_at: number | null;
    foreign_id: number;
}

export interface ISecureData {
    customer_components: string | null;
    customer_mobile: string | null;
    customer_phone: string | null;
    order_phones: string | null;
    success_phone: string | null;
}

export type TSmsListFromErp = { foreignId: number; smsList: ISmsFromErp[] };
