import React, { FunctionComponent, useMemo } from 'react';
import cn from 'classnames';
import ReactTooltip from 'react-tooltip';

import { IButtonProps } from '@UIElements/Button/models';
import Spinner from '@UIElements/Spinner';
import IcChevronUpSvg from '@UIElements/ButtonWithSplit/IcChevronUpSvg';
import TooltipPortal from '@components/main/TooltipPortal/TooltipPortal';
import LockSVG from '@/app/components/resources/SVG/Lock/LockSVG';


const Button: FunctionComponent<IButtonProps> = (props) => {
    const BUTTON_DISABLED_CLASS = 'button__disabled';
    const DEFAULT_VALUE = 'default';
    const STYLE_VARIANTS = ['0', '1', '2', '3', '4', '5', '6', '7'];
    const STYLE_SIZES = ['0', '1', '2', '10'];
    const BUTTON_VARIABLES = '--background-button';
    const CLR_TERTIARY = '#BCBCBC';
    const CLR_ACCENT = '#1665D8';

    const _variant = props.variant?.toString() || '';
    const _size = props.size || '';

    const {
        className = '',
        width = null,
        variant = STYLE_VARIANTS.includes(_variant) ? props.variant : DEFAULT_VALUE,
        size = STYLE_SIZES.includes(_size) ? props.size : DEFAULT_VALUE,
        text = '',
        onClick,
        disabled = false,
        isVisiblePreloader = false,
        preloaderColor = '',
        split = false,
        lock = false,
        dataCyValue = null,
        children,
        titleForTooltip,
        onMouseOut,
        onMouseEnter,
        fontFamily,
        padding,
    } = props;

    const eButtonContainer = cn([
        className,
        `v-type-${variant}`,
        `v-size-${size}`,
        disabled && BUTTON_DISABLED_CLASS,
    ]);

    const styleWrapper: Record<string, string> = { width: width || '' };

    const styleContainer: Record<string, string> = {
        [BUTTON_VARIABLES]: disabled ? CLR_TERTIARY : CLR_ACCENT,
        ...(width ? { width } : {}),
    };

    const isShowTooltip = useMemo<boolean>(
        () => !!titleForTooltip?.length,
        [titleForTooltip],
    );

    const tooltipData = useMemo<Record<string, any>>(
        () => ({
            'data-for': 'e-button__wrapper',
            'data-tip': titleForTooltip || 'TOOLTIP',
            'data-iscapture': true,
        }),
        [isShowTooltip, titleForTooltip],
    );

    const paddingLeftRight = padding ? padding : '0';

    const styleText = { fontFamily: fontFamily, padding: `0 ${paddingLeftRight}` };

    return (
        <>
            {isShowTooltip && (
                <TooltipPortal>
                    <ReactTooltip
                        id="e-button__wrapper"
                        place="top"
                        type="dark"
                        effect="float"
                        multiline={true}
                    />
                </TooltipPortal>
            )}
            <div
                className="e-button__wrapper"
                style={styleWrapper}
                {...isShowTooltip ? tooltipData : null}
                onMouseOut={onMouseOut}
                onMouseEnter={onMouseEnter}
            >
                <div
                    className={cn([
                        'e-button',
                        eButtonContainer && eButtonContainer,
                    ])}
                    onClick={onClick}
                    style={styleContainer}
                    {...dataCyValue ? { 'data-cy': dataCyValue } : null}
                >
                    {isVisiblePreloader && (
                        <Spinner
                            className={cn('e-button__Spinner')}
                            spinnerColor={preloaderColor}
                        />
                    )}
                    {!isVisiblePreloader && (
                        <div
                            className="link"
                        >
                            {lock && (
                                <LockSVG/>
                            )}
                            {children || <span style={styleText}>{text}</span>}
                            {split && (
                                <IcChevronUpSvg />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};


export default Button;
